<template>
  <div class="bigBox">
    <div class="titleDIv">
      <div class="line"></div>
      <div class="text">基本信息</div>
    </div>
    <div class="formDiv">
      <div class="itemDIv">
        <div class="label">订单时间：</div>
        <div class="value">{{ form.orderTime }}</div>
      </div>
      <div class="itemDIv">
        <div class="label">订单创建人：</div>
        <div class="value">{{ form.orderCreateUser }}</div>
      </div>
      <!-- <div class="itemDIv">
        <div class="label">分享用户：</div>
        <div class="value">{{ form.shareUser }}</div>
      </div> -->
      <div class="itemDIv">
        <div class="label">订单金额：</div>
        <div class="value">￥{{ form.totalMoney }}元</div>
      </div>
      <div class="itemDIv">
        <div class="label">产品数量：</div>
        <div class="value">{{ form.totalNumber }}件</div>
      </div>
    </div>
    <div class="titleDIv">
      <div class="line"></div>
      <div class="text">产品信息</div>
    </div>
    <div class="list">
      <div v-for="(item, index) in list" :key="index" class="listItem">
        <div class="img">
          <el-image
          style="width: 160px; height: 160px"
            :src="
              projectName +
              '/common/backstage/file/fileDownLoad?filePath=' +
              encodeURIComponent(item.mainPicture)
            "
            :preview-src-list="[
              projectName +
                '/common/backstage/file/fileDownLoad?filePath=' +
                encodeURIComponent(item.mainPicture),
            ]"
          >
          </el-image>
          <!-- <img
            :src="
              projectName +
              '/common/backstage/file/fileDownLoad?filePath=' +
              encodeURIComponent(item.mainPicture)
            "
            alt=""
            style="width: 160px; height: 160px"
          /> -->
        </div>
        <div class="smallDiv">
            <div class="right">{{item.name}}</div>
            <div class="right">
                <div>{{item.subtitle}}</div>
                <div v-if="item.specification">规格：{{item.specification}}</div>
            </div>
            <div class="right">
                <div>￥{{item.price}}元</div>
                <div>数量：{{item.productNumber}}</div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderdetail } from "../../api/index";
import { projectName } from "../../util/config";
export default {
  data() {
    return {
      form: {},
      list: [],
      projectName: projectName,
    };
  },
  mounted() {
    let orderId = this.$route.query.orderId;
    this.orderdetail(orderId);
  },
  methods: {
    orderdetail(orderId) {
      orderdetail({ orderId: orderId }).then((res) => {
        if (res.code == 1) {
          this.form = res.data || {};
          this.list = this.form.orderDetails || [];
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.bigBox {
  padding: 30px;
  .titleDIv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
    font-weight: 700;
    .line {
      width: 6px;
      height: 30px;
      background-color: #67c23a;
      margin-right: 10px;
      border-radius: 2px;
    }
  }
  .formDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
    padding-left: 40px;
    .itemDIv {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 30px;
      width: 33%;
      .label {
        // width: 200px;
        text-align: left;
      }
      .value {
        width: 200px;
        text-align: left;
      }
    }
  }
  .list {
    padding-left: 40px;
    .listItem {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    margin-bottom: 20px;

      .img{
        margin-right: 20px;
      }
      .smallDiv{
        flex: 1;
      }
    }
  }
  .right{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    div{
        flex: 1;
    }
  }
}
</style>